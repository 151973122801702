import React from "react";
import classNames from "classnames";

function SquareRadioButton(props) {
  const { onClick, checked } = props;
  return (
    <button className={classNames("square-radio", { "square-radio--clicked": checked })} onClick={onClick}  type="button">
      <div className="square-radio--content"></div>
    </button>
  );
}

export default SquareRadioButton;