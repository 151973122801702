/* eslint-disable */
import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import AXIOS from "../../axios-config";
import Layout from "../components/layout";
import SEO from "../components/seo";
import cogoToast from "cogo-toast";

//components
import Spinner from "../components/spinner";
import ImageBackground from "../components/imagebackground";
import settings from "../../settings";
import SquareRadioButton from "../components/SquareRadioButton";

//images
const topbannerDesktop = `${settings.IMAGES_BASE_URL}/images/locations/bmw-spartanburg-building.jpg`;

class EpcProgram extends React.Component {
  constructor(props) {
    super(props);

    this.contentRef = null;
  }

  state = {
    show: false,
    isLoading: false,
    receive_emails: false,
    showOtherBMWSalesPerson: true,
    bmw_salesperson: "",
    showBMWCertificate: true,
    designation_or_certificate_or_licence: "",
    designation_or_certificate_or_licence_other: "",
    IATA: '',
    average_quantity_of_events: 0,
    average_annual_billings: '',
    selectedOption: "option1",
  };

  componentDidMount() {
    this.setState();
  }

  scrollToContent = () => {
    if(!this.contentRef) return;

    this.contentRef.scrollIntoView({ behavior: "smooth" });
  };

  handleChangeCertificateSelection = (e) => {
    this.setState({ designation_or_certificate_or_licence: e.target.value });
    if (e.target.value == "Other") {
      this.setState({ showBMWCertificate: false });
    } else {
      this.setState({ showBMWCertificate: true });
    }
  };

  handleChangeAverageAnnualBillingSelection = (e) => {
    this.setState({ average_annual_billings: e.target.value });
  };

  handleMailsPermissionChange = (e) => {
    this.setState({
      receive_emails: e.target.checked,
    });
  };

  initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    bmw_salesperson: "",
    designation_or_certificate_or_licence: "",
    receive_emails: false,
    company_address: "",
    company_name: "",
    office_phone: "",
    mobile_phone: "",
    title: "",
    designation_or_certificate_or_licence_other: "",
    city: "",
    state: "",
    zip: "",
    average_annual_billings: ""
  };

  validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required("Please enter your first name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    lastname: Yup.string()
      .required("Please enter your last name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    company_name: Yup.string()
      .required("Please enter your company name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    company_address: Yup.string()
      .required("Please enter your company address")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    email: Yup.string()
      .email()
      .required("Please enter your email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Your email address is incorrect"
      ),
    city: Yup.string()
      .required("Please enter your city")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    state: Yup.string()
      .required("Please enter your state")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    zip: Yup.string()
      .required("Please enter your zip")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    office_phone: Yup.string()
      .required("Please enter your office phone")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Please enter a valid phone number"
      ),
    mobile_phone: Yup.string()
      .required("Please enter your mobile phone")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Please enter a valid phone number"
      ),
  });

  handleOptionChange = (value) => () => {
    this.setState({
      selectedOption: value
    });
  };

  handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (this.state.selectedOption == "option1") {
      values["bmw_salesperson"] =
        "I don’t have a sales rep, please assign one to me.";
    }
    values[
      "designation_or_certificate_or_licence"
    ] = this.state.designation_or_certificate_or_licence;
    values["receive_emails"] = this.state.receive_emails;
    values["average_annual_billings"] = this.state.average_annual_billings;

    this.setState({
      isLoading: true,
    });
    setSubmitting(true);

    AXIOS.post("/api/planner-registration-form", values)
      .then(() => {
        this.setState({
          isLoading: false,
        });
        resetForm();
        setSubmitting(false);
        cogoToast.success("Your form has been submitted successfully", {
          position: "top-center",
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        setSubmitting(false);
        cogoToast.error("Your form wasn't submitted", {
          position: "top-center",
        });
      });
  };
  render() {
    const { showBMWCertificate } = this.state;
    return (
      <Layout>
        <SEO
          title="BMW Performance Driving School | Group & Corporate Events"
          description="Spend the day at the BMW Performance Center with rooms to conduct team meetings, enjoy world-class cuisine and of course, get plenty of track time!"
          keywords="Team building activities"
        />
        <ImageBackground
          imageDesktop={topbannerDesktop}
          imageMobile={topbannerDesktop}
          imageAlt="BMW Touring Private Top Banner"
          scrollTo={this.scrollToContent}
        />
        <section className="getstarted getstarted-white" ref={(ref) => this.contentRef = ref}>
          <div className="container">
            <div className="getstarted__content">
              <h3>PLANNER APPLICATION FORM</h3>
              <br />
              <p>Questions? Read our FAQ</p>
              <a href="/epcfaq" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-bordered">Read our FAQ</button>
              </a>
              <br />

              <Formik
                initialValues={this.initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
              >
                {({ values, errors, touched, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <p>
                      <b>All fields mandatory</b>
                      <br /> Name of BMW Performance Center salesperson
                      referring you;
                    </p>
                    <div className="input">
                      <div className="form__group">
                        <div className="bmw_salesperson_group1">
                          <label className="epc-program__label">
                            <SquareRadioButton checked={this.state.selectedOption === "option1"}
                                      onClick={this.handleOptionChange("option1")} />
                            <span className="text">
                            I do not have a sales rep, please assign one to me
                            </span>
                          </label>
                          <label className="epc-program__label">
                            <SquareRadioButton checked={this.state.selectedOption === "option2"}
                                      onClick={this.handleOptionChange("option2")} />
                            <span className="text">
                              I am currently working with
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group input">
                          <Field
                            type="text"
                            disabled={
                              this.state.selectedOption == "option2"
                                ? false
                                : true
                            }
                            placeholder="I don’t have a sales rep, please assign one to me."
                            name="bmw_salesperson"
                            className={
                              touched.bmw_salesperson && errors.bmw_salesperson
                                ? "has-error"
                                : null
                            }
                          />
                          Amanda White, Doug McGrath, Irene Smith, Julie Soltis,
                          Karie Staelgraeve, Mike Renner
                          {errors.bmw_salesperson && touched.bmw_salesperson ? (
                            <span className="input-feedback">
                              {errors.bmw_salesperson}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="firstname">First Name</label>
                          <Field
                            type="text"
                            name="firstname"
                            className={
                              touched.firstname && errors.firstname
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.firstname && touched.firstname ? (
                            <span className="input-feedback">
                              {errors.firstname}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="input__group">
                          <label htmlFor="lastname">Last Name</label>
                          <Field
                            type="text"
                            name="lastname"
                            className={
                              touched.lastname && errors.lastname
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.lastname && touched.lastname ? (
                            <span className="input-feedback">
                              {errors.lastname}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="title">Title</label>
                          <Field
                            type="text"
                            name="title"
                            className={
                              touched.title && errors.title ? "has-error" : null
                            }
                          />
                          {errors.title && touched.title ? (
                            <span className="input-feedback">
                              {errors.title}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="input__group">
                          <label htmlFor="company_name">Company Name</label>
                          <Field
                            type="text"
                            name="company_name"
                            className={
                              touched.company_name && errors.company_name
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.company_name && touched.company_name ? (
                            <span className="input-feedback">
                              {errors.company_name}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className="input__group">
                        <label htmlFor="company_address">Company Address</label>
                        <Field
                          type="text"
                          name="company_address"
                          className={
                            touched.company_address && errors.company_address
                              ? "has-error"
                              : null
                          }
                        />
                        {errors.company_address && touched.company_address ? (
                          <span className="input-feedback">
                            {errors.company_address}
                          </span>
                        ) : (
                          <span className="input-feedback">&nbsp;</span>
                        )}
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="city">City</label>
                          <Field
                            type="tel"
                            name="city"
                            className={
                              touched.city && errors.city ? "has-error" : null
                            }
                          />
                          {errors.city && touched.city ? (
                            <span className="input-feedback">
                              {errors.city}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="input__group">
                          <label htmlFor="state">State</label>
                          <Field
                            type="tel"
                            name="state"
                            className={
                              touched.state && errors.state ? "has-error" : null
                            }
                          />
                          {errors.state && touched.state ? (
                            <span className="input-feedback">
                              {errors.state}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="input__group input__group__margin_left">
                          <label htmlFor="zip">Zip</label>
                          <Field
                            type="tel"
                            name="zip"
                            className={
                              touched.zip && errors.zip ? "has-error" : null
                            }
                          />
                          {errors.zip && touched.zip ? (
                            <span className="input-feedback">{errors.zip}</span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="office_phone">Office Phone</label>
                          <Field
                            type="tel"
                            name="office_phone"
                            className={
                              touched.office_phone && errors.office_phone
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.office_phone && touched.office_phone ? (
                            <span className="input-feedback">
                              {errors.office_phone}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="input__group">
                          <label htmlFor="mobile_phone">Mobile Phone</label>
                          <Field
                            type="tel"
                            name="mobile_phone"
                            className={
                              touched.mobile_phone && errors.mobile_phone
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.mobile_phone && touched.mobile_phone ? (
                            <span className="input-feedback">
                              {errors.mobile_phone}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className="input__group">
                        <label htmlFor="email">Email address</label>
                        <Field
                          type="text"
                          name="email"
                          className={
                            touched.email && errors.email ? "has-error" : null
                          }
                        />
                        {errors.email && touched.email ? (
                          <span className="input-feedback">{errors.email}</span>
                        ) : (
                          <span className="input-feedback">&nbsp;</span>
                        )}
                      </div>
                      <div className="form__group">
                        <div className="input__group input">
                          <label htmlFor="licenses">
                            Designation / Licenses / Certifications held
                          </label>
                          <Field
                            as="select"
                            name="designation_or_certificate_or_licence"
                            value={
                              this.state.designation_or_certificate_or_licence
                            }
                            onChange={this.handleChangeCertificateSelection}
                          >
                            <option value="Certified Meeting Professional (CMP)">
                              Certified Meeting Professional (CMP)
                            </option>
                            <option value="Certified Incentive Specialist (CIS)">
                              Certified Incentive Specialist (CIS)
                            </option>
                            <option value="Certitifed Incentive Travel Professional (CITP)">
                              Certitifed Incentive Travel Professional (CITP)
                            </option>
                            <option value="Certified Meeting Executive - Healthcare (CME-H)">
                              Certified Meeting Executive - Healthcare (CME-H)
                            </option>
                            <option value="Certified Meeting Manager (CMM)">
                              Certified Meeting Manager (CMM)
                            </option>
                            <option value="Certified Incentive Travel Executive (CITE)">
                              Certified Incentive Travel Executive (CITE)
                            </option>
                            <option value="Certified Association Executive (CAE)">
                              Certified Association Executive (CAE)
                            </option>
                            <option value="Certified Destination Management Executive (CDME)">
                              Certified Destination Management Executive (CDME)
                            </option>
                            <option value="Certified Exhibition Management (CEM)">
                              Certified Exhibition Management (CEM)
                            </option>
                            <option value="Certified Facilities Executive (CFE)">
                              Certified Facilities Executive (CFE)
                            </option>
                            <option value="Healthcare Meetings Compliance Certificate (HMCC)">
                              Healthcare Meetings Compliance Certificate (HMCC)
                            </option>
                            <option value="Certified Special Events Professional (CSEP)">
                              Certified Special Events Professional (CSEP)
                            </option>
                            <option value="Destination Management Certified Professional (DMCP)">
                              Destination Management Certified Professional
                              (DMCP)
                            </option>
                            <option value="Other">Other</option>
                          </Field>
                          <span className="input-feedback">&nbsp;</span>
                        </div>
                      </div>

                      {!showBMWCertificate && (
                        <>
                          <div className="form__group">
                            <div className="input__group input">
                              <label
                                htmlFor="designation_or_certificate_or_licence_other"
                              >
                                If other, please write the specific event
                                planner designation, license or certificate that
                                you currently hold.
                              </label>
                              <Field
                                type="text"
                                name="designation_or_certificate_or_licence_other"
                              ></Field>
                              <span className="input-feedback">&nbsp;</span>
                            </div>
                          </div>
                          <div className="form__group">
                            <div className="input__group input">
                              <label htmlFor="IATA">
                                Please provide your IATA
                              </label>
                              <Field type="text" name="IATA"></Field>
                              <span className="input-feedback">&nbsp;</span>
                            </div>
                          </div>
                          <div className="form__group">
                            <div className="input__group input">
                              <label htmlFor="average_quantity_of_events">
                                Average quantity of events you personally plan
                                and activate each year
                              </label>
                              <Field
                                type="number"
                                name="average_quantity_of_events"
                              ></Field>
                              <span className="input-feedback">&nbsp;</span>
                            </div>
                          </div>
                          <div className="form__group">
                            <div className="input__group input">
                              <label htmlFor="average_annual_billings">
                                Average annual billings you personally generate
                                from planned and activated events
                              </label>
                              <Field
                                as="select"
                                name="average_annual_billings"
                                value={this.state.average_annual_billings}
                                onChange={this.handleChangeAverageAnnualBillingSelection}
                              >
                                <option value="$10,000 - $50,000">
                                  $10,000 - $50,000
                                </option>
                                <option value="$50,001 - $100,000">
                                  $50,001 - $100,000
                                </option>
                                <option value="$100,001 - $250,000">
                                  $100,001 - $250,000
                                </option>
                                <option value="$250,000+">$250,000+</option>
                              </Field>
                              <span className="input-feedback">&nbsp;</span>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="checkbox">
                        <div className="checkbox__group">
                          <Field
                            type="checkbox"
                            name="receive_emails"
                            value={this.state.receive_emails}
                            checked={this.state.receive_emails}
                            onChange={this.handleMailsPermissionChange}
                          />
                          <label htmlFor="receive_emails">
                            I provide BMW with permission to send emails to me
                            regarding news and special
                            <br /> offers about group event programs at the BMW
                            Performance Center
                          </label>
                        </div>
                        {errors.receive_emails && touched.receive_emails ? (
                          <span className="input-feedback">
                            {errors.receive_emails}
                          </span>
                        ) : (
                          <span className="input-feedback">&nbsp;</span>
                        )}
                      </div>
                    </div>
                    <button
                      className="btn btn-blue"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {this.state.isLoading ? <Spinner /> : "Submit"}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default EpcProgram;
